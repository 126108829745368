import {
    Avatar,
    BlockStack, Button,
    Card,
    Divider, Grid, Icon, IndexTable, InlineGrid,
    InlineStack,
    MediaCard,
    Modal, ProgressBar, ResourceItem,
    ResourceList, Spinner,
    Text,
    Thumbnail,
    Badge as PolarisBadge, Popover, ActionList, Page, Layout, ChoiceList, CalloutCard, Select
} from "@shopify/polaris";
import {
    CameraIcon,
    CheckSmallIcon,
    ImageAddIcon,
    ImageIcon,
    LogoInstagramIcon,
    PageIcon,
    RefreshIcon
} from '@shopify/polaris-icons';
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../lib/contextLib";
import {useLocation} from "react-router-dom";
import CustomDrawer from "./common/custom_drawer";
import {getShopifyProductImagesAPI} from "../servies/products.services";

import {createPredictionApi, getPredictionStatus} from "../servies/prediction.services";

import {
    Badge,
    Col,
    RadioTile,
    RadioTileGroup,
    Row,
    Stack,
    useMediaQuery,
    Button as RSuiteButton,
    Button as RsuiteButton, ButtonGroup, List, Dropdown, SelectPicker
} from "rsuite";
import * as PropTypes from "prop-types";
import {handleMouseMoveZoom, handleMouseOutZoom} from "./common/util";
import {CustomModal} from "./common/common_modals";
import {PlanModal} from "./modal/PlanModal";
import {TipModal} from "./modal/TipModal";
import {Layer} from "recharts";
import CustomSelect from "./CustomSelect";
import CustomSelectWithHoverVideo from "./CustomSelect";
import CustomPolarisSelect from "./CustomSelect";


function SpacingBackground(props) {
    return null;
}

SpacingBackground.propTypes = {children: PropTypes.node};
export const CreateDrawer = ({
                                 productName,
                                 isImageModalOpen,
                                 setIsImageModalOpen,
                                 selectedProductID,

                                 view
                             }) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState(false);
    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [isInline] = useMediaQuery('xl');
    const [startImageId, setStartImageId] = useState(null);
    const [endImageId, setEndImageId] = useState(null);
    const [startImageSrc, setStartImageSrc] = useState(null);
    const [endImageSrc, setEndImageSrc] = useState(null);
    const [totalSelectedImage, setTotalSelectedImage] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]);
    const [userPrompt, setUserPrompt] = useState('');
    const [openTipModal, setOpenTipModal] = useState(false);
    const [popoverActive, setPopoverActive] = useState(false);
    const [onBlankBoxClick, setOnBlankBoxClick] = useState(false);


    const {
        isRefresh, setIsRefresh,
        shopState, setShopState,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);

    const handleSelectImage = (id, src) => {
        console.log(id)
        if (startImageId === id) {
            setStartImageId(null);
            setStartImageSrc(null);
            setStartImageSrc(endImageSrc);
            setStartImageId(endImageId);
            setEndImageId(null);
            setEndImageSrc(null);
        } else if (endImageId === id) {

            setEndImageId(null);
            setEndImageSrc(null);

        } else if (startImageId === null) {
            setStartImageSrc(src);
            setStartImageId(id);
        } else if (endImageId === null) {
            setEndImageSrc(src);
            setEndImageId(id);
        }

    };

    const getBadgeContent = (id) => {

        if (startImageId === id) return '1';
        // if (startImageId === id) return 'Start Image';
        if (endImageId === id) return '2';
        // if (endImageId === id) return 'End Image';
        return null;
    };

    const handleMakeAiVideo = () => {
        const inputData = {
            'product_id': selectedProductID,
            'start_image_id': startImageId,
            'end_image_id': endImageId,
            'start_image_src': startImageSrc,
            'end_image_src': endImageSrc,
            'user_prompt': userPrompt
        }

        setIsCreationLoading(true);

        createPredictionApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }

            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                const bannerData = {
                    title: 'Your AI video is being created',
                    tone: 'success',
                    description: `<b>${shopState.email}</b> will get emailed when the AI video is ready for the <b>${productName}</b>`
                }
                setIsRefresh(true)
                setBannerMessage(bannerData)
                setIsImageModalOpen(false)
                setIsCreationLoading(false)

            })


    }

    useEffect(() => {
        const inputData = {
            product_id: selectedProductID
        }
        setLoading(true)

        getShopifyProductImagesAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }
                console.log('data', data)
                const images = data.images;
                setProductImages(images);
                console.log('aiImages', images)

            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }, []);


    const onCameraMovementChange = (value) => {
        console.log({value})
        setUserPrompt(value)
    }

    const options = [
        /*{
            label: 'STATIC',
            value: 'static',

        },*/
        {
            disable:true,
            label: 'MOVE LEFT',
            value: 'camera move left',

        },
        {
            label: 'MOVE RIGHT',
            value: 'camera move right',

        },
        {
            label: 'MOVE UP',
            value: 'camera move up',


        },
        {
            label: 'MOVE DOWN',
            value: 'camera move down',

        },
        {
            label: 'PUSH IN',
            value: 'camera push in',

        },
        {
            label: 'PULL OUT',
            value: 'camera pull out',
        },
        /*{
            label: 'PAN LEFT',
            value: 'panLeft',
        },
        {
            label: 'PAN RIGHT',
            value: 'panRight',
        },
        {
            label: 'ORBIT LEFT (Rotate Counter Clockwise)',
            value: 'orbitLeft',
        },
        {
            label: 'ORBIT RIGHT (Rotate Clockwise)',
            value: 'orbitRight',
        },
        {
            label: 'CRANE UP (Circle Up)',
            value: 'craneUp',
        },
        {
            label: 'CRANE DOWN (Circle Down)',
            value: 'zoomOut',
        },*/

    ];


    return <>
        <TipModal
            title={'Which image work best for AI Video'}
            open={openTipModal}
            setOpen={setOpenTipModal}

        />
        <CustomDrawer
            placement={'bottom'}
            size={'calc(100% - 120px)'}
            open={isImageModalOpen}
            setOpen={setIsImageModalOpen}
            title={productName}
        >
            {loading ? <Spinner/> : null}


            <BlockStack gap={'1600'}>

                <Card>

                    {
                        startImageId === null && endImageId === null ?
                            <Text variant="headingLg" as="span" alignment={"start"} tone={"base"}>
                                Select an image to make a video
                            </Text>
                            :
                            <>
                                <BlockStack gap="200" align={'start'} inlineAlign={'stretch'}>
                                    <InlineStack align={'center'}>

                                        <div
                                            style={{
                                                padding: '10px',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                marginBottom: '10px',
                                                borderRadius: '6px',
                                                objectFit: 'contain',
                                            }}
                                        >
                                            <Card>
                                                <img src={startImageSrc}
                                                     alt={`Thumbnail ${startImageSrc}`}
                                                     style={{
                                                         width: '190px',
                                                         height: '190px',
                                                         objectFit: 'contain',
                                                         borderRadius: '6px',
                                                     }}
                                                />
                                            </Card>
                                        </div>


                                        <div
                                            style={{
                                                padding: '10px',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                marginBottom: '10px',
                                                borderRadius: '6px',
                                                objectFit: 'contain',
                                            }}
                                        >

                                            {
                                                endImageId === null ?

                                                    <Card>
                                                        <div
                                                            style={{

                                                                width: '190px',
                                                                height: '190px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                cursor: 'pointer',
                                                                backgroundColor: '#f4f6f8',

                                                            }}
                                                            onClick={() => {
                                                                setOnBlankBoxClick(true)
                                                                const timeout = setTimeout(() => {
                                                                    setOnBlankBoxClick(false)
                                                                }, 500)

                                                            }}
                                                        >

                                                            <Text as="span" alignment={"center"}
                                                                  tone={"base"}>
                                                                <p>Select End image</p>
                                                                <p>(Optional)</p>
                                                            </Text>
                                                        </div>
                                                    </Card>
                                                    :
                                                    <Card>
                                                        <img src={endImageSrc}
                                                             alt={`Thumbnail ${endImageSrc}`}
                                                             style={{
                                                                 width: '190px',
                                                                 height: '190px',
                                                                 // maxWidth: '100%',
                                                                 // maxHeight: '100%',
                                                                 objectFit: 'contain',
                                                                 borderRadius: '6px',
                                                             }}
                                                        />
                                                    </Card>


                                            }

                                        </div>

                                    </InlineStack>
                                    <Stack justifyContent={'flex-end'}>
                                       {/* <Stack.Item>
                                            <div>
                                                <RSuiteButton
                                                    appearance="link"
                                                    hidden={shopState.predictions_left <= 1}
                                                    onClick={() => {
                                                        setOpenTipModal(true);
                                                    }}
                                                >
                                                    Tip
                                                </RSuiteButton>

                                                {
                                                    shopState.predictions_left <= 3 ?
                                                        <>
                                                            You have only <PolarisBadge
                                                            tone={'warning'}
                                                        >
                                                            <b>{shopState.predictions_left}</b>
                                                        </PolarisBadge> predictions left,
                                                            <RsuiteButton
                                                                appearance={'link'}
                                                                onClick={
                                                                    (e) => {
                                                                        setOpenPlanModal(true)
                                                                    }
                                                                }
                                                            >
                                                                <PolarisBadge
                                                                    tone={'warning'}
                                                                >
                                                                    Upgrade for more
                                                                </PolarisBadge>
                                                            </RsuiteButton>
                                                        </>

                                                        : null
                                                }
                                            </div>

                                        </Stack.Item>*/}
                                        <Stack.Item alignSelf={'flex-end'}>
                                            <InlineStack gap={"200"} align={"end"}>
                                                <SelectPicker
                                                    data={options}
                                                    onChange={onCameraMovementChange}
                                                    style={{
                                                        border: '1px solid gray',
                                                        borderRadius: '7px',
                                                    }}
                                                    searchable={false}
                                                    placeholder="Camera Movement (Optional)"
                                                />
                                                <Button
                                                    onClick={handleMakeAiVideo}
                                                    tone={'success'}
                                                    variant={'primary'}
                                                    loading={isCreationLoading}
                                                >
                                                    Make AI Video
                                                </Button>
                                            </InlineStack>

                                        </Stack.Item>

                                    </Stack>

                                </BlockStack>

                            </>

                    }
                </Card>

                <Card padding={'1200'}>
                    <Grid columns={{xs: 3, sm: 3, md: 3, lg: 5, xl: 5}}>
                        {productImages.map(({image_id, src}) => (
                            <Grid.Cell key={image_id}>


                                <div
                                    style={{
                                        border: startImageId === image_id || endImageId === image_id ? '2px solid #FBBC04' : 'none',

                                        // width: '190px',
                                        // overflow: 'hidden',
                                        marginRight: '20px',
                                        backgroundColor: '#f4f6f8',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        // margin: '40px',
                                        boxSizing: 'border-box'
                                    }}
                                    onClick={() => handleSelectImage(image_id, src)}
                                >
                                    <Card padding={'200'}>
                                        <img
                                            src={src}
                                            alt={`Thumbnail ${image_id}`}
                                            style={{
                                                width: '190px',
                                                height: '190px',
                                                // maxWidth: '100%',
                                                // maxHeight: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '6px',
                                            }}
                                        />
                                        {(startImageId === image_id || endImageId === image_id) && (
                                            <>
                                                {/*<Badge
                                                    content={getBadgeContent(image_id)}
                                                    style={{position: 'absolute', top: '8px', right: '8px'}}
                                                />*/}
                                                <div style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',  // semi-transparent black overlay
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '8px',
                                                    color: 'white',
                                                    fontSize: '24px',
                                                    fontWeight: 'bold',
                                                    zIndex: 1,
                                                }}>
                                                    {getBadgeContent(image_id)}
                                                </div>
                                            </>


                                        )}

                                        {(startImageId !== image_id && endImageId !== image_id && onBlankBoxClick) && (

                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(77,135,204,0.27)',  // semi-transparent black overlay
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '8px',
                                                color: 'white',
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                                zIndex: 1,
                                            }}>

                                            </div>


                                        )}

                                    </Card>
                                </div>

                            </Grid.Cell>
                        ))}
                    </Grid>
                </Card>

            </BlockStack>
        </CustomDrawer>
    </>


}